import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const MainHero = ({
  title,
  subTitle,
  heroImage,
  height = "is-large",
  crop = "0",
}) => {
  return (
    <section
      className={`hero ${height}`}
      style={{
        position: "relative",
        // background:
        //   "linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 81%)",
      }}
    >
      <Img
        // fluid={heroImage.childImageSharp.fluid}
        loading="eager"
        fadeIn={false}
        className="is-hidden-mobile"
        sizes={heroImage.childImageSharp.sizes}
        style={{
          position: "absolute",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: "-1",
        }}
        imgStyle={{ objectFit: "cover", objectPosition: `0 ${crop}%` }}
      />
      <div className="hero-body">
        <div className="container">
          <div>
            <h1
              className="title has-text-centered-mobile"
              style={{
                maxWidth: "900px",
                textShadow: "0px 0px 5px black",
              }}
            >
              <span
              // style={{
              //   background: "#1f434a",
              //   padding: "0.3rem 0.8rem",
              // }}
              >
                {title}
              </span>
            </h1>
            {subTitle && (
              <h2
                className="subtitle has-text-link is-size-4-tablet has-text-centered-mobile"
                // style={{ textShadow: "0px 0px 9px black" }}
              >
                <span
                  style={{
                    background: "#1f434a",
                  }}
                >
                  {subTitle}
                </span>
              </h2>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainHero

export const query = graphql`
  fragment HeroImageFragment on File {
    childImageSharp {
      fluid(maxHeight: 1200, cropFocus: NORTH) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
