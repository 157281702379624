import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { FiPhone } from "react-icons/fi"

const ContactMe = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     contactImage: file(
  //       relativePath: { eq: "AdobeStock_88565994_Preview.jpeg" }
  //     ) {
  //       childImageSharp {
  //         fluid(maxHeight: 1200) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="hero is-medium" style={{ position: "relative" }}>
      {/* <Img
        fluid={data.contactImage.childImageSharp.fluid}
        style={{
          position: "absolute",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: "-1",
        }}
      /> */}
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="box" style={{ maxWidth: "850px", margin: "auto" }}>
            <h2
              className="has-text-primary is-size-5 is-size-3-tablet"
              style={{ marginBottom: "0.6rem", paddingBottom: "1rem" }}
            >
              Call to schedule a free 30 minute consultation
            </h2>
            {/* 
            <p
              style={{ maxWidth: "750px", margin: "auto" }}
              className="is-size-4 has-text-primary"
            >
              {subTitle}
            </p> */}
            <hr
              style={{ maxWidth: "50px", margin: "1.1rem auto 1.9rem auto" }}
            />
            <p
              className="title has-text-primary"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FiPhone style={{ marginRight: "0.7rem" }} />
              <a
                className="has-text-primary"
                style={{ textDecoration: "none" }}
                href="tel:1-248-408-3058"
              >
                248-408-3058
              </a>
            </p>
            <p
              className="is-size-6 is-size-4-tablet has-text-primary"
              style={{ maxWidth: "650px", margin: "auto" }}
            >
              I welcome your call to discuss your questions and concerns and
              help you get started on this path of awareness and health. I offer
              a free 30 minute initial phone or office consultation to assist
              you.
              {/*You can also email me at{" "}
              <a
                href="mailto: sydneyreiter@gmail.com"
                className="has-text-primary"
              >
                sydneyreiter@gmail.com
              </a> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactMe
