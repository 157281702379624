import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            imageUrl
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const titleTemp = title || seo.title
      const description = seo.description
      const image = seo.image
      const url = seo.siteUrl
      const datePublished = false

      return (
        <React.Fragment>
          <Helmet>
            {/* General tags */}
            <title>{titleTemp}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <link rel="canonical" href={url} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            {/* {isBlogPost ? <meta property="og:type" content="article" /> : null} */}
            <meta property="og:title" content={titleTemp} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            {/* <meta name="twitter:creator" content={seo.social.twitter} /> */}
            <meta name="twitter:title" content={titleTemp} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
          </Helmet>
          {/* <SchemaOrg
              isBlogPost={isBlogPost}
              url={url}
              title={title}
              image={image}
              description={description}
              datePublished={datePublished}
              siteUrl={seo.siteUrl}
              author={seo.author}
              organization={seo.organization}
              defaultTitle={seo.title}
            /> */}
        </React.Fragment>
      )
    }}
  />
)

export default SEO
